	/*
  	Flaticon icon font: Flaticon
  	Creation date: 24/09/2020 14:02
  	*/
	
	@font-face {
	    font-family: "Flaticon";
	    src: url("./Flaticons.eot");
	    src: url("./Flaticons.eot?#iefix") format("embedded-opentype"), url("./Flaticons.woff2") format("woff2"), url("./Flaticons.woff") format("woff"), url("./Flaticons.ttf") format("truetype"), url("./Flaticons.svg#Flaticon") format("svg");
	    font-weight: normal;
	    font-style: normal;
	}
	
	@media screen and (-webkit-min-device-pixel-ratio:0) {
	    @font-face {
	        font-family: "Flaticon";
	        src: url("./Flaticons.svg#Flaticon") format("svg");
	    }
	}
	
	[class^="flaticon-"]:before,
	[class*=" flaticon-"]:before,
	[class^="flaticon-"]:after,
	[class*=" flaticon-"]:after {
	    font-family: Flaticon;
	    font-style: normal;
	}
	
	.flaticon-message:before {
	    content: "\f100";
	}
	
	.flaticon-ring:before {
	    content: "\f101";
	}
	
	.flaticon-earrings:before {
	    content: "\f102";
	}
	
	.flaticon-bracelet:before {
	    content: "\f103";
	}
	
	.flaticon-necklace:before {
	    content: "\f104";
	}
	
	.flaticon-bracelet-1:before {
	    content: "\f105";
	}
	
	.flaticon-phone:before {
	    content: "\f106";
	}
	
	.flaticon-bracelet-2:before {
	    content: "\f107";
	}
	
	.flaticon-diamond:before {
	    content: "\f108";
	}