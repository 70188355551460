@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&family=Prata:ital,wght@0,400;0,700;1,400&family=Roboto:wght@400;500;700&display=swap");


header.header-two .search {
    position: relative;
}

header.header-two .search .search-icon {
    position: relative;
    display: block;
}

header.header-two .search .search-icon .close-icon,
header.header-two .search .search-icon .open-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

header.header-two .search .search-icon .close-icon {
    visibility: hidden;
    opacity: 0;
}

header.header-two .search .search-icon.active .close-icon {
    visibility: visible;
    opacity: 1;
}

header.header-two .search .search-icon.active .open-icon {
    visibility: hidden;
    opacity: 0;
}

header.header-two .search .search-form {
    position: absolute;
    right: 0;
    width: 350px;
    display: none;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    top: calc(100% + 27px);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    header.header-two .search .search-form {
        top: calc(100% + 17px);
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    header.header-two .search .search-form {
        top: calc(100% + 10px);
    }
}

@media (max-width: 575px) {
    header.header-two .search .search-form {
        top: calc(100% + 20px);
        width: 250px;
    }
}

@media (max-width: 399px) {
    header.header-two .search .search-form {
        right: -100%;
        top: calc(100% + 12px);
    }
}

header.header-two .search .search-form form {
    position: relative;
}

header.header-two .search .search-form form input {
    width: 100%;
    background-color: #353530;
    height: 60px;
    border: none;
    padding-left: 30px;
    padding-right: 60px;
    font-size: 14px;
}

@media (max-width: 575px) {
    header.header-two .search .search-form form input {
        height: 50px;
    }
}

header.header-two .search .search-form form button {
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 100%;
    z-index: 1;
    background-color: #fcd462;
    font-size: 14px;
    color: #fff;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

header.header-two .search .search-form form button:hover {
    background-color: #fff;
    color: #1c1b19;
}

@media (min-width: 1198px) and (max-width: 767px) {
    header .header-two .milliLogo {
        width: 25px;
    }
}

.custom-widget {
    border: 1px solid #ccc;
    padding: 10px;
    width: 300px;
    /* Adjust as needed */
}

.custom-cart-btn-area {
    display: flex;
    flex-direction: column;
}

.custom-cart-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.custom-actions {
    display: flex;
    justify-content: space-between;
}

.custom-main-btn {
    padding: 5px 10px;
}

.custom-btn-filled {
    background-color: #007bff;
    /* Example color */
    color: #fff;
    border: none;
}

.custom-btn-borderd {
    background-color: transparent;
    border: 1px solid #007bff;
    /* Example color */
    color: #007bff;
    /* Example color */
}

.custom-cart-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.custom-cart-item .img {
    margin-right: 10px;
}

.custom-cart-item .content {
    flex-grow: 1;
}

.custom-cart-item .content h5 {
    margin-top: 0;
}

.custom-cart-item .content p {
    margin-bottom: 0;
}

a:hover {
    color: #E56EEB;
}

.nice-select .list li.option:hover {
    background: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
}

.main-btn.btn-filled {
    background: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
    color: #fff !important;
}

.main-btn.btn-filled:hover {
    color: var(--gradients-01, linear-gradient(90deg, #E56EEB 0%, #8863FB 100%));
}

.main-btn {
    border: 2px solid #8863FB !important;
}

.main-btn.btn-border:hover {
    background-color: #E56EEB !important;
}

.nice-select::before {
    color: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
}

.nice-select::before {
    color: #E56EEB;
}

.condo-item.hotel-intro:hover:before {
    background-color: rgba(145, 60, 149, 0.612);
}

.input-group .icon {
    color: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
}

.section-title.white-color span.title-tag {
    color: #E56EEB;
}

.arrow-style .slick-arrow:hover,
.arrow-style .slick-arrow.next-arrow {
    background-color: #E56EEB !important;
}

.on-load-modal .modal-dialog .modal-content .modal-content {
    background: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
}

.lr-topbar .lr-topbar-controls.style-2>ul>li>a:hover,
.lr-topbar .lr-topbar-controls>ul>li>a:hover {
    color: #E56EEB;
}

header .nav-container .menu-items ul li .submenu li a.active {
    background-color: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
    border-color: #E56EEB;
}

header .nav-container .menu-items ul li .submenu li a:hover {
    background-color: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
    border-color: #E56EEB;
}

header.header-two .header-info .item i {
    background: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
}

header.header-two .search .search-form form button {
    background-color: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
}

header .nav-container.breakpoint-on .nav-menu {
    background-color: #E56EEB;
}

header.header-two .header-info .item i {
    background: #E56EEB;
}

header.header-two .search .search-form form button {
    right: 0;
    top: 0;
    width: 60px;
    height: 100%;
    background-color: #E56EEB;
    font-size: 14px;
    color: #fff;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.sigma-header .sigma-notification {
    background: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
}

.sigma-header .cart-dropdown-menu .cart-item .content p {
    color: #E56EEB;
}

.sigma-header .sigma-main-menu>.menu-item {
    color: #fff;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigma-blog-block>a:hover {
    color: #fff;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .menu-item-has-children>.sub-menu>.menu-item>a {
    padding: 1px 0;
}

.sigma-header .sigma-main-menu>.menu-item:hover>a {
    color: #E56EEB;
}

.sigma-header .sigma-main-menu .menu-item-has-children>.sub-menu>li.menu-item:hover>a {
    background-color: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
    border-color: #E56EEB;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigm-megamenu-nav>.nav-item>.nav-link.active {
    border-right: 3px solid #E56EEB;
    color: #E56EEB;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigma-blog-block>a:hover {
    color: #E56EEB;
}

.sigma-header .sigma-main-menu .menu-item-has-children>.sub-menu .gift {
    position: absolute;
    top: 120%;
    left: 0;
    right: 0;
    background: transparent;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-out 0s;
    width: 250px;
    z-index: 10;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.sigma-header .sigma-main-menu .menu-item-has-children>.sub-menu .daily-wear {
    width: 100%;
    margin: auto;
    justify-content: center;
}

.sigma-header .sigma-main-menu .menu-item-has-children>.sub-menu .daily-wear img {
    position: relative;
}

.sigma-header .sigma-main-menu .menu-item-has-children>.sub-menu .daily-wear h4 {
    color: #fff;
    position: absolute;
    bottom: 20px;
    margin-left: 15px;
}

.offcanvas-wrapper .offcanvas-widget a.offcanvas-close {
    color: #E56EEB;
}

.offcanvas-wrapper .offcanvas-widget .widget.search-widget form button {
    background-color: #E56EEB;
}

.offcanvas-wrapper .offcanvas-widget .widget.nav-widget ul li a:hover {
    color: #E56EEB;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li a:hover {
    color: #E56EEB;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li.active {
    color: #E56EEB;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li.active::before {
    color: #E56EEB;
}

.breadcrumb-area .breadcrumb-text .widget.search-widget form button {
    background-color: #E56EEB;
}

.vertical-text ul li a:hover {
    color: #E56EEB;
}

.about-section .down-arrow-wrap a.down-arrow {
    background: #E56EEB;
}

.about-section .about-title span.title-tag span {
    color: #E56EEB;
}

.about-section .about-features li a {
    color: #E56EEB;
}

.about-section .about-features li a:hover {
    background: #E56EEB;
}

.about-section .about-features-boxes .single-feature-box .icon {
    color: #E56EEB;
}

.about-section .about-features-boxes .single-feature-box.dark a:hover {
    color: #E56EEB;
}

.cta-section .cta-inner .cta-features .single-feature .icon {
    color: #E56EEB;
}

.cta-section .cta-inner .cta-features .single-feature:hover .icon {
    background-color: #E56EEB;
}

.cta-section .cta-inner::after {
    background-color: #E56EEB;
}

.room-gallery-cta .cta-text span {
    color: #E56EEB;
}

.text-block.bg-black .section-title .title-tag {
    color: #E56EEB;
}

.text-block.with-pattern .pattern-wrap {
    background: #E56EEB;
}

.testimonial-box .client-img .check {
    background-color: #E56EEB;
}

.testimonial-box .clinet-post {
    color: #E56EEB;
}

.testimonial-slider ul.slick-dots li.slick-active button {
    background-color: #E56EEB;
}

.blog-style-2 .blog-post .blog-data .post-link:hover {
    color: #E56EEB;
}

.features-loop .feature-box .icon {
    color: #E56EEB;
}

.features-loop .feature-box.dark-box .icon {
    color: #E56EEB;
}

.room-gird-loop .room-box .room-desc span.price,
.room-gird-loop .room-box .room-desc a.book-btn {
    color: #E56EEB;
}

.room-gird-loop .room-box .room-desc a.book-btn:hover {
    color: #E56EEB;
}

.room-list-loop .room-box .room-desc a.book-btn:hover {
    color: #E56EEB;
}

.room-list-loop .room-box .room-desc .price {
    color: #E56EEB;
}

.room-slider .rooms-content-wrap .room-content-box .slider-count .current {
    color: #E56EEB;
}

.room-slider .rooms-content-wrap .room-content-box .icon {
    color: #E56EEB;
}

.room-slider .rooms-content-wrap .room-content-box .room-content-slider ul.slick-dots li.slick-active button {
    background-color: #E56EEB;
}

.room-slider .rooms-slider-two .single-rooms-box .room-desc .price {
    color: #E56EEB;
}

.room-slider .rooms-slider-two .slick-arrow:hover,
.room-slider .rooms-slider-two .slick-arrow.next-arrow {
    background-color: #E56EEB;
}

.room-details .deatils-box .title-wrap .price {
    color: #E56EEB;
}

.room-details .deatils-box .room-fearures .room-fearures-list li i {
    color: #E56EEB;
}

.room-details .deatils-box .room-rules .room-rules-list li::before {
    color: #E56EEB;
}

.room-details .room-booking-form .title::before {
    background-color: #E56EEB;
}

.counter-section .counter-box .icon i {
    color: #E56EEB;
}

.counter-section .counter-box.counter-box-two .icon {
    color: #E56EEB;
}

.contact-part .contact-info .info-box .icon {
    color: #E56EEB;
}

.offers-area .offer-boxes-loop .offer-box .offer-desc {
    background-color: #353530;
    border-top: 6px solid #E56EEB;
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .price {
    color: #E56EEB;
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .offer-feature li i {
    color: #E56EEB;
}

.post-box .post-desc .cat {
    background-color: #E56EEB;
}

.post-box .post-desc .post-meta li a:hover {
    color: #E56EEB;
}

.post-box .post-desc .post-footer .read-more a:hover {
    color: #E56EEB;
}

.post-box.with-video .post-media .play-icon {
    background-color: #E56EEB;
    color: #fff;
}

.post-box.quote-post::before,
.post-box.quote-post::after {
    background-color: #E56EEB;
}

.news-details-box .entry-content .cat {
    background-color: #E56EEB;
}

.news-details-box .entry-content .post-meta li a:hover {
    color: #E56EEB;
}

.news-details-box .entry-content blockquote {
    background-color: #E56EEB;
}

.news-details-box .entry-footer .tag-and-share .tag li a:hover {
    background-color: #E56EEB;
    color: #fff;
}

.news-details-box .entry-footer .post-nav .prev-post span,
.news-details-box .entry-footer .post-nav .next-post span {
    color: #E56EEB;
}

.news-details-box .entry-footer .author-info-box .info-text span {
    color: #E56EEB;
}

.pagination-wrap ul li a:hover {
    background-color: #E56EEB;
    color: #fff;
}

.pagination-wrap ul li.active a {
    background-color: #E56EEB;
    color: #fff;
}

.sidebar .widget .widget-title::before {
    background-color: #E56EEB;
}

.sidebar .widget.search-widget form button {
    background-color: #E56EEB;
}

.sidebar .widget.categories-widget ul li a:hover {
    background-color: #E56EEB;
}

.sidebar .widget.socail-widget ul li a:hover {
    background-color: #E56EEB;
}

.sidebar .widget.popular-tag-widget ul li a:hover {
    background-color: #E56EEB;
}

.sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter::before {
    color: #E56EEB;
}

.sidebar .widget.popular-tag-widget ul li a:hover {
    background-color: #E56EEB;
    color: #fff;
}

.booking-form form input,
.booking-form form select,
.booking-form form .nice-select {
    border-bottom: 2px solid #E56EEB;
    color: #fff;
}

.booking-form form .nice-select {
    border-color: #E56EEB !important;
}

.booking-form form .inputs-filed .icon {
    color: #E56EEB;
}

.booking-form form button {
    background-color: #E56EEB;
    color: #fff;
}

.booking-form form button:hover {
    background-color: #E56EEB;
}

.categories-box-layout .categories-box .icon {
    background: #E56EEB;
}

.categories-box-layout .categories-box .icon {
    background: #E56EEB;
    color: #1c1b19;
}

.category-box-sec .category-box-wrap:hover {
    background-color: #E56EEB;
}

.book-room-style2:after {
    background-color: #E56EEB;
}

.calender-box ul li .calender-input-box .calender-input-inner .next-day:hover,
.calender-box ul li .calender-input-box .calender-input-inner .prev-day:hover {
    background-color: #E56EEB;
    color: #fff;
}

.places-details .title-wrap .link a:hover {
    background-color: #E56EEB;
    color: #fff;
}

.restaurant-tab-area .restaurant-rood-list .nav-link.active,
.restaurant-tab-area .restaurant-rood-list .nav-link:hover {
    background-color: #E56EEB;
}

.restaurant-tab-area .food-box .thumb .price {
    background-color: #E56EEB;
    color: #fff;
}

.content-box-2 .price {
    color: #E56EEB;
}

.team-section .team-loop .member-box .member-info span {
    color: #E56EEB;
}

.team-section .team-loop .member-box .social-links li a:hover {
    background-color: #E56EEB;
}

.team-section .team-loop .member-box .socail-trigger:hover {
    background-color: #E56EEB;
    color: #fff;
}

.widget .irs--flat .irs-handle>i:first-child,
.widget .irs--flat .irs-single {
    background-color: #E56EEB;
}

.food-box.shop-box .thumb .badges .price {
    background-color: #E56EEB;
}

.shop-box .thumb .badges .price.discounted {
    color: #ffffff;
}

.harvest-section .form-section .widget.search-widget form button {
    background-color: #E56EEB;
}

footer .footer-widget-area .widget.site-info-widget .social-links a:hover {
    background-color: #E56EEB;
}

footer .footer-widget-area .widget.nav-widget ul li a:hover {
    color: #E56EEB;
}

footer .footer-widget-area .widget.contact-widget .contact-lists .contact-box .icon {
    color: #E56EEB;
}

footer .footer-widget-area.light-theme .widget.site-info-widget .social-links a:hover {
    background-color: #E56EEB;
}

footer .copyright-area .social-links a:hover {
    background-color: #E56EEB;
}

footer .copyright-area .footer-menu ul li a:hover {
    color: #E56EEB;
}

.footer-subscibe-area.style-2 .subscribe-text .subscribe-form button {
    background-color: #E56EEB;
    color: #fff;
}

.desc .price {
    color: #E56EEB;
}

.food-box.shop-box .thumb .badges .price {
    background-color: #E56EEB;
    color: #fff;
}

.shop-box .thumb .button-group a {
    color: #E56EEB;
    background-color: #353530;
}

.shop-box .thumb .button-group a:hover {
    color: #fff;
    background-color: #E56EEB;
}

.food-box.shop-box .desc .link {
    color: #E56EEB;
}

.shop-box.shop-box-2 .thumb .button-group a:hover {
    background-color: transparent;
    color: #E56EEB;
}

.widget .irs--flat .irs-handle>i:first-child,
.widget .irs--flat .irs-single {
    background-color: #E56EEB;
}

.widget .irs--flat .irs-bar {
    background-color: #E56EEB;
}

.widget .irs--flat .irs-single:before {
    border-top-color: #E56EEB;
}

.widget .irs--flat .irs-handle {
    background-color: #E56EEB;
}

.widget .filter-color .checkbox input:checked~.custom-box {
    border: 1px solid #E56EEB;
    background-color: #E56EEB;
}

.cart-flex .remove {
    color: #E56EEB;
}

.detail-slider-1 .image-box .price {
    background-color: #E56EEB;
    color: #fff;
}

.color-sec .color-box label .choose-material:hover {
    background-color: #E56EEB;
    color: #fff;
}

.color-sec .color-box label input:checked+span {
    background-color: #E56EEB;
    color: #fff;
}

.shop-detail-content .quantity-cart .main-btn:hover {
    border-color: #E56EEB;
    color: #E56EEB;
}

.shop-detail-content .quantity-box button:hover {
    background-color: #E56EEB;
    color: #fff;
}

.product-description .nav-tabs .nav-link.active {
    color: #E56EEB;
}

.wishlist .share-wishlist .social-media a:hover {
    background-color: #fcd462;
}

.login-form a:hover {
    color: #E56EEB;
}

.login-form .social-buttons button.main-btn.facebook {
    border: 2px solid #E56EEB;
}

.account-tabs .nav-tabs .nav-item .nav-link.active,
.account-tabs .nav-tabs .nav-item .nav-link:hover {
    background-color: #E56EEB;
}

.tab-content .address-content .extra-info .btn-link:hover {
    color: #E56EEB;
}

.service-wrapper .service-content h3 a:hover {
    color: #E56EEB;
}

.location-wrapper:hover .location-thumb:before {
    background: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
    opacity: 1;
}

.sigma-footer .sigma-footer-top .sigma-backto-top .back-to-top i {
    background-color: #E56EEB;
}

.sigma-footer .sigma-footer-box .sigma-footer-box-top .ft-social-media>li:hover>a {
    background-color: #E56EEB;
}

.sigma-footer .sigma-copyright-inner a:hover,
.sigma-footer .sigma-footer-contact ul>li:hover>a {
    color: #E56EEB;
}

.sigma-footer .sigma-footer-nav .ft-nav>.menu-item:hover>a {
    padding-left: 10px;
    color: #E56EEB;
}

.sigma-footer .sigma-footer-contact ul>li>i {
    color: #E56EEB;
}

.offcanvas-wrapper .offcanvas-widget .widget .widget-title::before {
    background-color: #E56EEB;
}

.wishlist .share-wishlist .social-media a:hover {
    background-color: #E56EEB;
}

.back-to-top {
    background-color: #E56EEB;
}

/* Harvest last section */

.harvest-section .how-it-work .oval {
    float: left;
    border: 2px solid #E56EEB;
    background-color: transparent;
    width: 350px;
    height: 350px;
    border-radius: 50%;
    position: relative;
    padding: 120px 0;
    list-style-type: none;
}

.harvest-section .how-it-work .oval .oval-container {
    padding-top: 5px;
    padding-bottom: 20px;
    padding-left: 100px;
    margin-left: -20px;
    margin-right: 25px;
    position: absolute;
    background-color: #1c1b19;
}

.harvest-section .how-it-work .oval .oval-container:before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #E56EEB;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 22px;
}

.harvest-section .how-it-work .oval .oval-container:after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #E56EEB;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 28px;
}

.harvest-section .how-it-work .oval .oval-container .oval-text {
    font-size: 30px;
    line-height: 41px;
    color: #fff;
    font-weight: bold;
    padding-bottom: 5px;
}

.harvest-section .how-it-work .oval .oval-container .oval-content {
    font-size: 18px;
    line-height: 26px;
    color: #fff;
}

.harvest-section .how-it-work .description {
    float: left;
    width: 750px;
    position: relative;
    margin-left: -55px;
}

.harvest-section .how-it-work .description .outer-wrapper {
    position: relative;
    padding: 23px 0;
}

.harvest-section .how-it-work .description .outer-wrapper.clearfix {
    content: " ";
    display: table;
}

.harvest-section .how-it-work .step-bar {
    position: static;
    width: 26px;
    height: 26px;
    border: 2px solid #E56EEB;
    border-radius: 50%;
    text-align: center;
    background: #1c1b19;
    z-index: 1;
    top: 50%;
    display: inline-block;
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    line-height: 21px;
}

.harvest-section .how-it-work .outer-wrapper .step-lines {
    display: inline-block;
    width: 145px;
    height: 5px;
    position: relative;
    vertical-align: middle;
}

.harvest-section .how-it-work .outer-wrapper .step-lines:after {
    content: "";
    height: 1px;
    border-bottom: 2px dashed #E56EEB;
    position: absolute;
    top: 40%;
    width: 100%
}

.harvest-section .how-it-work .description .outer {
    border: 1px dashed #E56EEB;
    width: 68px;
    height: 68px;
    border-radius: 98px;
    background: transparent;
    display: inline-block;
    vertical-align: middle;
    clear: right;
}

.harvest-section .how-it-work .description .outer .inner {
    border: 1px solid #E56EEB;
    width: 58px;
    height: 58px;
    background: transparent;
    border-radius: 88px;
    position: relative;
    left: 4px;
    top: 4px;
}

.harvest-section .how-it-work .description .outer .inner .pay-monthly {
    margin-top: 2px;
    margin-left: 9px;
    background-position: -10px -113px;
    width: 50px;
    height: 50px;
    display: inline-block;
    background: url(../img/icon/calander.svg) no-repeat 0 center;
    overflow: hidden;
    text-align: left;
    vertical-align: middle;
}

.harvest-section .how-it-work .description .outer .inner .discount {
    margin-top: 8px;
    margin-left: 10px;
    background-position: -10px -113px;
    width: 45px;
    height: 42px;
    display: inline-block;
    background: url(../img/icon/discount.svg) no-repeat 0 center;
    overflow: hidden;
    text-align: left;
    vertical-align: middle;
}

.harvest-section .how-it-work .description .outer .inner .redeem {
    margin-top: 7px;
    margin-left: 10px;
    background-position: -10px -113px;
    width: 45px;
    height: 42px;
    display: inline-block;
    background: url(../img/icon/bag.svg) no-repeat 0 center;
    overflow: hidden;
    text-align: left;
    vertical-align: middle;
}


.harvest-section .how-it-work .description .text-content {
    display: inline-block;
    margin-left: 30px;
    vertical-align: middle;
}


.harvest-section .how-it-work .description .text-content .feature-title {
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    font-weight: 600;
    padding: 0 0 5px
}

.harvest-section .how-it-work .description .text-content .feature-content {
    font-size: 16px;
    line-height: 22px;
}

.harvest-section .how-it-work .description .Discount {
    padding-left: 42px;
}



/* ======   Milliharvest   ===== */

.harvest-section {
    align-items: center;
}

.harvest-section .harvest-tag h1:after {
    content: "";
    width: 3px;
    height: 110px;
    /* border-radius: 50%; */
    background-color: #E56EEB;
    display: inline-block;
    position: absolute;
    bottom: -25px;
    /* left: 28px; */
    right: 10px;
}

.harvest-section .harvest-tag {
    align-items: center;
}

.harvest-section .shop-detail-content .harvest-title {
    font-size: 75px;
}

.harvest-section .shop-detail-content .harvest-text {
    font-size: 26px;
    margin-left: 100px;
    margin-right: 80px;
    color: #ffffff;
}

.harvest-section .shop-detail-content .harvest-title-text {
    font-size: 21px;
    margin-left: 38px;
    color: #ffffff;
}

.harvest-section .shop-detail-content .harvest-text-tag {
    font-size: 24px;
    margin-left: 100px;
    margin-right: 80px;
    color: #ffffff;
}

.harvest-section .form-section .form-text {
    justify-content: center;
    align-items: center;
    display: flex;
}

.harvest-section .form-section .form-text h2 {
    padding-left: 20px;
    padding-right: 20px;
    color: #E56EEB;
}

.harvest-section .form-section .form-text p {
    font-size: 24px;
    color: #ffffff;
}

.harvest-section .form-section .widget.search-widget form input {
    width: 100%;
    background-color: #fff;
    height: 60px;
    border: none;
    padding-left: 30px;
    padding-right: 60px;
    font-size: 14px;
}

.harvest-section .form-section .widget.search-widget form .main-btn.btn-filled.monthly {
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 2;
    /* width: 30%; */
    height: 60px;
    z-index: 1;
    background-color: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
    font-size: 10px;
    color: #fff;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.harvest-section .form-section .widget.search-widget form .main-btn.btn-filled.start {
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    /* width: 25%; */
    height: 60px;
    z-index: 1;
    background-color: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
    font-size: 14px;
    color: #fff;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.shop-detail-content .quantity-cart .cart-btn .discl {
    /* padding-left: 40px; */
    font-size: 13px;
}

.offcanvas-wrapper .offcanvas-widget .widget .cart-items-box {
    width: 100%;
}

.offcanvas-wrapper .offcanvas-widget .widget .cart-items-box img {
    width: 80px;
    height: 80px;
}

.offcanvas-wrapper .offcanvas-widget .widget .cart-items-box .content {
    width: 100%;
}


/* Checkout Check Box */

.checkout-form .input-group.input-group-two .country-code {
    border-radius: 0;
    padding: 10px;
    width: 70%;
    border-style: none;
    color: #ffffff;
}

.checkout .check {
    margin-left: 16px;
    width: 100%;
    align-items: center;
    margin-bottom: 30px;
}

.checkout .check input {
    border-radius: 10px;
    height: 20px;
    width: 20px;
    padding: 0;
    border: none;
}

/* .checkout .check .check-label { */
/* margin: 10px; */
/* } */

.features-loop .feature-box {
    width: 370px;
    height: 370px;
    padding: 40px;
    background-color: #353530;
    font-size: 14px;
    background-color: #353530;
    border: 2px solid #40403b;
    position: relative;
    margin-top: 30px;
    /*=======  Dark Style  =======*/
}

.container-fluid .room-items .row {
    margin-left: -7.5px;
    margin-right: -7.5px;
}

.container-fluid .room-items .row>.col,
.container-fluid .room-items .row>[class*='col-'] {
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.container-fluid .room-items .room-box {
    width: 100%;
    height: 280px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-top: 15px;
}

.container-fluid .room-items .room-box.extra-height {
    height: 575px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .container-fluid .room-items .room-box.extra-height {
        height: 280px;
    }
}

@media (max-width: 767px) {
    .container-fluid .room-items .room-box.extra-height {
        height: 280px;
    }
}

.container-fluid .room-items .room-box::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(17, 17, 17, 0.50196) 0%, rgba(17, 17, 17, 0.25) 44%, rgba(17, 17, 17, 0) 100%);
    content: '';
}

.container-fluid .room-items .room-box .room-bg {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

.container-fluid .room-items .room-box .room-content {
    position: absolute;
    left: 30px;
    bottom: 30px;
    z-index: 2;
    right: 30px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .container-fluid .room-items .room-box .room-content {
        left: 15px;
    }
}

.container-fluid .room-items .room-box .room-content h3 {
    font-size: 36px;
}

.container-fluid.style-2 .room-items .room-box .room-content h3 {
    margin-bottom: 10px;
}

.container-fluid.style-2 .room-items .room-box .room-content p {
    max-width: 80%;
}

@media (max-width: 767px) {
    .container-fluid .room-items .room-box .room-content h3 {
        font-size: 26px;
    }
}

.container-fluid .room-items .room-box .room-content h3 a {
    color: #fff;
}

.container-fluid .room-items .room-box .room-content span {
    color: #fff;
    padding-bottom: 15px;
    font-weight: 700;
}

.container-fluid .room-items .room-box .room-content span i {
    margin-right: 10px;
}

@media (max-width: 767px) {
    .container-fluid .room-items .room-box .room-content span {
        font-size: 14px;
    }
}

.container-fluid .room-items .room-box .room-link {
    border: 2px solid rgba(255, 255, 255, 0.2);
    width: 60px;
    height: 60px;
    position: absolute;
    right: 15px;
    bottom: 0;
    border-radius: 50%;
    z-index: 3;
    color: #fff;
    font-size: 18px;
    line-height: 60px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
}

@media (max-width: 767px) {
    .container-fluid .room-items .room-box .room-link {
        height: 40px;
        width: 40px;
        text-align: center;
        right: 10px;
        line-height: 40px;
    }
}

.container-fluid .room-items .room-box .room-link:hover {
    background-color: #353530;
    color: #fff;
}

.container-fluid .room-items .room-box.extra-wide .room-link {
    right: 40px;
}

.container-fluid .room-items .room-box:hover .room-link {
    opacity: 1;
    visibility: visible;
    bottom: 30px;
}

.container-fluid .room-items .room-box:hover .room-bg {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.d-justifiySBtn {
    justify-content: space-between;
}

.checkout-term {
    display: flex;
    align-items: center;
}

.checkout-width {
    width: 100%;
}

.input-group.input-group-two ::placeholder {
    opacity: 1;
    color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 1047px) {

    header.header-three .main-menu-area,
    .sigma-header .sigma-main-menu>.menu-item {
        font-size: 10px;
    }
}

header .nav-container .toggle {
    margin-right: 15px;
    padding-left: 25px;
}

@media (max-width: 991px) {
    .lr-topbar-controls {
        display: none;
    }
}

.Shop-section .city {
    justify-content: center;
}

.sigma-mobile-header .sigma-mobile-header-inner i {
    color: #fff;
    font-size: 18px;
    padding: 0 17px;
    margin-right: 0;
}

.sigma-mobile-header .sigma-mobile-header-inner .sigma-notification {
    width: 12px;
    height: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 8px;
    margin-right: 0;
    position: relative;
    top: -10px;
    left: 38px;
    margin-left: -20px;
}

header .nav-container .site-logo {
    max-width: 90px;
    margin-right: 40px;

}

.sigma-mobile-header .sigma-hamburger-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 45px;
    height: 45px;
    /* border: 2px solid #40403b; */
    justify-content: center;
}

@media only screen and (min-width: 300px) and (max-width: 500px) {
    header .nav-container .site-logo {
        max-width: 100px;
        margin-right: 15px;

    }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
    header .nav-container .site-logo {
        max-width: 100px;
        margin-right: 80px;

    }
}

footer .footer-widget-area .widget.site-info-widget {
    color: #fff;
}

footer .footer-widget-area .widget.site-info-widget .join-us button {
    border-color: #E56EEB;
    background-color: transparent;
    color: #fff;
    padding: 10px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    box-shadow: none;
}

/* footer .footer-widget-area .widget.site-info-widget .join-us .join:hover {
    border-color: #8863FB;
    background-color: #8863FB;
    color: #fff;
} */

.input-group.input-group-two input,
.input-group.input-group-two select,
.input-group.input-group-two textarea,
.input-group.input-group-two select {
    color: #fff;
}

a,
a:hover {
    color: #E56EEB;
}

.checkout .checkout-form .sub-btn {
    justify-content: center;
    text-align: center;
}

.otp-form {
    padding: 60px;
    text-align: center;
    background-color: transparent;
    /* border-top: 1px solid #40403b;
    border-right: 1px solid #40403b;
    border-bottom: 1px solid #40403b; */
}

.otp-form .otp-input input {
    height: 60px;
    padding: 0 34px 0 34px;
    background-color: #353530;
    color: #a3a3a3;
}

.otp-form h2 {
    margin-bottom: 50px;
    color: #fff;
    font-weight: 600;
}

.otp-form a {
    text-decoration: underline;
    color: #fff;
    display: block;
}

.otp-form a:hover {
    color: #fcd462;
}

.otp-form .login-btn {
    line-height: 44px;
    box-shadow: none;
}

.otp-form .form-seperator {
    margin: 30px 0;
    text-align: center;
}

.otp-form .form-seperator span {
    position: relative;
    display: flex;
    align-items: center;
    color: #a7a2a2;
    font-size: 14px;
}

.otp-form .form-seperator span:before {
    content: '';
    flex: 1;
    background-color: rgb(255 245 245 / 55%);
    height: 1px;
    margin-right: 20px;
}

.otp-form .form-seperator span:after {
    content: '';
    flex: 1;
    background-color: rgb(255 245 245 / 55%);
    height: 1px;
    margin-left: 20px;
}

.otp-form .social-buttons button.main-btn {
    line-height: 45px;
    width: 100%;
    box-shadow: none;
}

.otp-form .social-buttons button.main-btn.facebook {
    border: 2px solid #fcd462;
    background-color: transparent;
}

.otp-form .social-buttons button i {
    margin-right: 5px;
}


@media (max-width: 575px) {
    .otp-form {
        padding: 30px;
    }
}

.checkout-form .input-group.input-group-two .country-code {
    border-radius: 0;
    padding: 10px;
    width: 70%;
    border-style: none;
    color: #ffffff;
}

.register .check {
    /* margin-left: 16px; */
    width: 100%;
    align-items: center;
    margin-top: 30px;
}

.register .check input {
    border-radius: 10px;
    height: 20px;
    width: 20px;
    padding: 0;
    border: none;
}

.register .check .check-label {
    margin: 10px;
}

.color-sec .color-box label .yellow-gold {
    background-color: #fcd462;
    color: #6f6f6f;
    /* color: #fdfdfd; */
}

.color-sec .color-box label .rose-gold {
    background-color: #FFD0D0;
    color: #6f6f6f;
    /* color: #fdfdfd; */
}

.color-sec .color-box label .white-gold {
    background-color: #fdfdfd;
    color: #6f6f6f;
}

.shop-detail-content .checkout-term {
    align-items: center;
}

/* .shop-detail-content .title h6 {
    display: flex;
} */

.shop-detail-content .checkout-term .radio-label {
    margin-top: 9px;
}

.shop-detail-content .checkout-term .radio {
    width: 16px;
    padding: 0;
    margin-right: 5px;
}

.shop-detail-content .quantity-cart .cart-btn .discl {
    /* padding-left: 40px; */
    font-size: 13px;
}

.shop-detail-image .discl {
    color: #fff;
}

.shop-detail-image .rating {
    color: #fff;
}

.shop-detail-content .checkout-term .size-list {
    height: 44px;
    padding: 10px;
}

.Shop-section .shop-pin input {
    width: 100%;
    background-color: #353530;
    height: 45px;
    border: none;
    padding-left: 30px;
    padding-right: 60px;
    font-size: 14px;
}

.Shop-section .shop-pin button {
    width: 45%;
    background: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
    color: #fff;
    height: 45px;
    font-weight: 700;
    font-size: 14px;
    font-family: "Rubik", sans-serif;
    border: none;
    padding-left: 30px;
    padding-right: 30px;

}

.Shop-section .shop-pin p {
    color: #fff;
    font-size: 16px;
    font-family: "Rubik", sans-serif;
}

.shop-detail-content .modal-content .modal-inner {
    display: none;
}

.size-modal .modal-dialog {
    max-width: 900px;
}

.size-modal .modal-dialog .modal-content .modal-content {
    position: relative;
    border-radius: 0;
    padding: 40px;
    background: #fcd462;
    background-size: cover;
    background-position: center;
    z-index: 1;
}

.size-modal .modal-dialog .modal-content .modal-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    z-index: -1;
}

.size-modal .modal-header {
    padding: 0;
    border: 0;
}

.size-modal .modal-header button {
    text-shadow: none;
    box-shadow: none;
    color: #fff;
    opacity: 1;
    font-weight: 100;
    padding: 0;
    margin: 0 0 0 auto;
    width: 40px;
    height: 40px;
}

.size-modal .modal-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.size-modal .modal-inner {
    width: 100%;
    max-width: 800px;
}

.size-modal .modal-inner .title {
    color: #fff;
    font-size: 35px;
    margin-bottom: 20px;
}

.size-modal .modal-inner p {
    color: #a3a3a3;
    margin-bottom: 20px;
}

.size-modal form input {
    margin-top: 15px;
    padding: 20px;
    height: 50px;
    font-size: 14px;
    font-family: "Rubik", sans-serif;
}

.size-modal form button {
    margin-top: 20px;
    display: block;
    width: 25%;
    background: var(--gradients-01, linear-gradient(90deg, #8863FB 0%, #E56EEB 100%));
    color: #fff;
    height: 50px;
    font-weight: 700;
    font-size: 14px;
    font-family: "Rubik", sans-serif;
    border: none;
    padding-left: 30px;
    padding-right: 30px;
    float: right;
}

.widget-cart-info .custom-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-weight: 600;
    padding-bottom: 25px;
    /* border-bottom: 1px solid #40403b; */
}

/* .customize {
    background-image: url("../img/bg/about-1.png") ;
    size: cover;
    background-repeat: no-repeat;
    background-size:cover;
    background-attachment: fixed;
  background-position: center;
} */
.customize .custom-form .check.input-group {
    align-items: center;
}

.customize .custom-form .check.input-group .radio {
    height: 18px;
    width: 18px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.team-section .check.input-group .pay{
    background-color: #353530;
    height: 70px;
    padding: 0 30px;
    border-radius: 5px;
}

.customize .custom-form .check.input-group .check-box {
    height: 20px;
    width: 20px;
    margin-right: 8px;
    margin-bottom: 10px;
}



/* .customize .custom-form .drop-sec .drop-one {
    margin-left: 10px;
}

.customize .custom-form .drop-sec .drop-two {
    margin-left: 30px;
}

.customize .custom-form .drop-sec .drop-three {
    margin-left: 10px;
}

.customize .custom-form .drop-sec .drop-four {
    margin-left: 10px;
} */

#droparea {
    width: 500px;
    /* height: auto; */
    text-align: center;
    /* padding: 220px 360px; */
    background-color: #353530;
    cursor: pointer;
}

#droparea input {
    text-align: center;
    background-color: #353530;
    cursor: pointer;
}


@media only screen and (max-width: 1811px) and (min-width: 1200px) {
    #droparea input {
        width: 250px;
        height: 200px;
        padding: 0;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 991px) {
    #droparea input {
        width: 435px;
        height: 200px;
        padding: 0 80px;
    }
}

@media only screen and (max-width: 990px) and (min-width: 768px) {
    #droparea input {
        width: 310px;
        height: 200px;
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 575px) {
    #droparea input {
        width: 230px;
        height: 200px;
        padding: 0 ;
    }
}

@media only screen and (max-width: 575px) and (min-width: 300px) {
    #droparea input {
        width: 100%;
        height: 200px;
        /* padding: 0 130px ; */
    }
}

@media only screen and (max-width: 767px) and (min-width: 575px) {
    ::-webkit-file-upload-button {
        visibility: hidden;
        margin-left: -20px;
    }
}

@media only screen and (max-width: 574px) and (min-width: 300px) {
    ::-webkit-file-upload-button {
        visibility: hidden;
        margin-left: -20px;
    }
}

::-webkit-file-upload-button {
    visibility: hidden;
}

#image-view {
    position: absolute;
    top: 30%;
    left: 10%;
    right: 25%;
}

@media only screen and (max-width: 1811px) and (min-width: 1200px) {
    #image-view {
        position: absolute;
        /* top: 30%; */
        left: 13%;
        right: 10%;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 991px) {
    #image-view {
        position: absolute;
        /* top: 30%; */
        left: 20%;
        right: 20%;
    }
}

@media only screen and (max-width: 990px) and (min-width: 768px) {
    #image-view {
        position: absolute;
        /* top: 30%; */
        left: 10%;
        right: 10%;
    }
}

@media only screen and (max-width: 767px) and (min-width: 575px) {
    #image-view {
        position: absolute;
        /* top: 30%; */
        left: 10%;
        right: 10%;
    }
}

@media only screen and (max-width: 575px) and (min-width: 300px) {
    #image-view {
        position: absolute;
        /* top: 30%; */
        left: 10%;
        right: 10%;
    }
}

#image-view i {
    font-size: 26px;
}

@media only screen and (max-width: 767px) and (min-width: 575px) {
    #image-view i {
        font-size: 22px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 300px) {
    .customize .custom-form .check.input-group .radio {
        height: 18px;
        width: 18px;
        margin-right: 5px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 300px) {
    .customize .custom-form .check.input-group .gquality {
        height: 18px;
        width: 18px;
        margin-left: -20px;
        margin-right: 5px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 300px) {
    .customize .custom-form .check.input-group .vvsgh {
        height: 18px;
        width: 18px;
        margin-left: 30px;
        margin-right: 5px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 388px) and (min-width: 300px) {
    .customize .custom-form .check.input-group .dquality {
        height: 18px;
        width: 18px;
        /* margin-left: -30px; */
        margin-right: 5px;
        margin-bottom: 10px;
    }
}

.color-sec .color-box label .custom-color {
    height: 60px;
    width: 120px;
    padding: 25px;
    /* margin-right: 50px; */
    text-align: center;
}

@media only screen and (max-width: 767px) and (min-width: 300px) {
    .color-sec .color-box label .custom-color {
        height: 45px;
        width: 77px;
        /* width: 95%; */
        padding: 15px 5px;
        /* margin-right: 5px; */
        text-align: center;
    }
}
@media only screen and (max-width: 992px) and (min-width: 768px) {
    .color-sec .color-box label .custom-color {
        width: 90px;
        /* width: 95%; */
        padding: 23px 5px;
        /* margin-right: 5px; */
        text-align: center;
    }
}

@media only screen and (min-width: 300px) and (max-width: 768px) {
    .color-sec .color-box label .rose {
        margin-left: 1px;
    }
}

@media only screen and (min-width: 300px) and (max-width: 768px) {
    .color-sec .color-box label .whiteg {
        margin-left: 1px;
    }
}

.account-tabs .btn-link {
    color: #a3a3a3;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    float: right;
}

/* .tab-content .address-content .extra-info .btn-link:hover {
    color: #fcd462;
} */
.order-head h3:hover {
    color: #E56EEB;
}

.order-table table,
th,
td {
    border-style: hidden;
}

.order-table table th {
    border-style: hidden;
}

.order-table table td {
    border-style: hidden;
}

.track-dtl {
    display: flex;
    justify-content: center;
}

.track-dtl .order-placed .plc::before {
    position: absolute;
    top: 116px;
    display: inline-block;
    content: "";
    border-top: 1px dashed rgb(132 90 223);
    width: 270px;
    opacity: 0.5;
}

.track-dtl .order-placed .plc {
    display: flex;
    align-items: center;
}

.track-dtl .order-placed .plc-dsc {
    margin-top: 10px;
    margin-right: 10px;
}

.track-dtl .order-picked .pkd::before {
    position: absolute;
    top: 116px;
    display: inline-block;
    content: "";
    border-top: 1px dashed rgb(132 90 223);
    width: 270px;
    opacity: 0.5;
}

.track-dtl .order-picked .pkd {
    display: flex;
    align-items: center;
}

.track-dtl .order-picked .pkd-dsc {
    margin-top: 10px;
    margin-right: 10px;
}

.track-dtl .order-ofd .ofd::before {
    position: absolute;
    top: 116px;
    display: inline-block;
    content: "";
    border-top: 1px dashed rgb(132 90 223);
    width: 270px;
    opacity: 0.5;
}

.track-dtl .order-ofd .ofd {
    display: flex;
    align-items: center;
}

.track-dtl .order-ofd .ofd-dsc {
    margin-top: 10px;
    margin-right: 10px;
}

/* .track-dtl .order-del .del::before{
    position: absolute;
    top: 70px;
    display: inline-block;
    content: "";
    border-top: 1px dashed rgb(132 90 223);
    width: 270px;
    opacity: 0.5;
} */

.track-dtl .order-del .del {
    display: flex;
    align-items: center;
}

.track-dtl .order-del .del-dsc {
    margin-top: 10px;
    margin-right: 10px;
}

#png {
    margin-right: 15px;
}


.track-dtl .order-placed .splc::before {
    /* rotate: 90deg; */
    position: absolute;
    top: 116px;
    display: inline-block;
    content: "";
    border-right: 1px dashed rgb(132 90 223);
    width: 270px;
    opacity: 0.5;
}

#custom-item{
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #fff !important;
}